import '../styles/global.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { config, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { GoogleTagManager } from '@next/third-parties/google';
import type { AppProps } from 'next/app';
import { Nunito } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';

config.autoAddCss = false;

library.add(fas);

const nonito = Nunito({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-nunito',
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${nonito.style.fontFamily} !important;
        }
      `}</style>
      <Component {...pageProps} className="overflow-hidden" />
      <GoogleTagManager gtmId="GTM-K9223C6X" />
    </>
  );
};

export default appWithTranslation(MyApp);
